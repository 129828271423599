.video-source-title {
  color: #1890ff;
  padding: 10px;
  cursor: pointer; /* 마우스 포인터를 손가락 모양으로 변경 */
  transition: background-color 0.3s; /* 부드러운 색 변경 효과 */
}

.video-source-title:hover {
  color: #1677ff; /* 마우스 호버시 배경색 변경 */
}

/* 테이블이 부모 컴포넌트의 전체 너비를 차지하도록 설정 */
.ant-table {
  width: 100%; /* 부모의 너비에 맞춤 */
  table-layout: auto; /* 자동 레이아웃으로 설정하여 콘텐츠에 맞게 컬럼 너비를 조정 */
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  word-wrap: break-word; /* 긴 텍스트 줄바꿈 */
  white-space: normal;   /* 내용이 너무 길 경우 줄바꿈 허용 */
}

/* 각 컬럼의 너비에 대한 최소값 및 최대값 설정 */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  min-width: 100px; /* 컬럼의 최소 너비 */
  max-width: 400px; /* 컬럼의 최대 너비 */
  padding: 8px;
}

.ant-table-cell {
  vertical-align: middle;
  overflow-wrap: break-word; /* 테이블 셀 안에서 줄바꿈이 발생하도록 */
}

.ant-table {
  border-collapse: collapse; /* 테이블 경계선이 하나로 결합되도록 설정 */
}

/* JSON 데이터 셀 */
.json-cell {
  white-space: pre-wrap; /* 줄바꿈 적용 */
  word-wrap: break-word; /* 긴 텍스트 줄바꿈 */
  overflow: auto; /* 스크롤 적용 */
  max-height: 200px; /* 최대 높이 */
}

input:read-only {
  background-color: #e9e9e9;
}
