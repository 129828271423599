.transfer-container {
    display: flex;
    justify-content: space-between; /* 좌우로 패널 나눔 */
    height: 100%; /* 부모 높이에 맞게 100% */
    width: 100%; /* 부모 너비에 맞게 100% */
    gap: 20px; /* 좌우 패널 간의 공간 */
}

.transfer-panel {
    flex: 1; /* 좌우 패널이 균등하게 나뉨 */
    display: flex;
    flex-direction: column;
    height: 100%; /* 부모 높이에 맞추기 */
}

.ant-transfer-list {
    height: 100%; /* Transfer 리스트를 부모 높이에 맞게 */
    display: flex;
    flex-direction: column;
}

.ant-transfer-list-body {
    flex: 1;
    overflow-y: auto; /* 내용이 넘칠 때 스크롤 */
}

.transfer-wrapper {
    flex: 1; /* 좌우 패널을 균등하게 나누기 */
    height: 100%;
}
