.playlist-search {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 좌우 끝단 배치 */
    width: 100%;
    padding: 10px;
}

.playlist-search input {
    width: 100%; /* 검색창 크기 */
    margin-right: 10px; /* 검색창과 버튼 사이의 간격 */
}

.yellow-button-wrapper {
    margin-right: auto; /* YellowButton을 좌측에 정렬하고, 나머지 버튼들을 우측으로 밀기 */
    margin-left: 10px; /* 간격 설정 */
}

.filter-btn, .active-btn {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    color: black;
    font-weight: bold;
    margin-left: 10px;
}

.filter-btn:hover, .active-btn:hover {
    background-color: #e5e5e5;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

