.split-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.split-container .ant-typography {
  margin-bottom: 16px;
}

/* 카드 내부 버튼 컨테이너 스타일 */
.custom-card .ant-card-body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* 버튼 그룹 스타일 */
.custom-card .ant-card-body .ant-btn {
  width: 100%;
  margin-bottom: 8px;
}

/* 태블릿 디바이스 (768px 이하) */
@media screen and (max-width: 768px) {
  .split-container {
    gap: 16px;
  }

  .split-container .ant-typography {
    margin-bottom: 12px;
  }
}

/* 모바일 디바이스 (480px 이하) */
@media screen and (max-width: 480px) {
  .split-container {
    gap: 12px;
  }

  .split-container .ant-typography {
    margin-bottom: 8px;
    font-size: 20px !important;
  }

  .custom-card .ant-card-body {
    gap: 8px;
  }
}
