.upload-video-list-item {
  margin-bottom: 16px;
}

.video-meta {
  display: flex;
  align-items: flex-start;
}

.video-info {
  margin-left: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video-info .ant-form-item {
  margin-top: 5px;
  margin-bottom: 0;
}

.video-info p {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
}

.file-name {
  margin-bottom: 8px;
  font-weight: bold;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions button {
  margin-left: 8px;
}

.progress-bar {
  width: 100%;
}
