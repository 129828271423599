.poster-preview-container {
  display: flex;
  width: 30%;
  flex-direction: column;
  border: 1px solid #ddd; /* 회색 테두리 추가 */
  border-radius: 4px; /* 모서리 둥글게 */
  padding: 10px; /* 내부 여백 */
}

.poster-preview-container > span {
  margin-bottom: 10px;
}

.poster-uploaded-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  border: 1px solid #ddd; /* 회색 테두리 추가 */
  border-radius: 4px; /* 모서리 둥글게 */
  padding: 10px; /* 내부 여백 */
  margin-left: 10px;
}

.poster-uploaded-container > button {
  margin-top: 10px;
}
