.tabs-container {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  height: 100%;

  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  justify-content: flex-start;
}

.ant-tabs-tab {
  font-size: 16px;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
  color: #1890ff;
}

.ant-tabs-ink-bar {
  background-color: #1890ff;
}
