.header-container {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  padding-bottom: 10px;
  width: 100%;
  margin: 0 auto;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  padding-left: 1%; /* 왼쪽 패딩 제거 */
}

.base-page-component {
  padding-left: 1%; /* 왼쪽 패딩 제거 */
}

.base-page-component table {
  padding-top: 10px;
}

/* 태블릿 디바이스 (768px 이하) */
@media screen and (max-width: 768px) {
  .base-page-component {
    padding: 16px;
  }
}

/* 모바일 디바이스 (480px 이하) */
@media screen and (max-width: 480px) {
  .base-page-component {
    padding: 12px;
  }
}
