.playlist-container {
    padding: 20px;
    background-color: #fff;
}

.playlist-title {
    font-weight: bold;
    color: #007bff;
}

.action-btn {
    background-color: #ffd700;
    border: none;
    color: black;
    height: 32px;
    width: 32px;
}

.action-btn:hover {
    background-color: #e5c100;
    border: none;
}

.delete-btn {
    background-color: gray;
}

.delete-btn:hover {
    background-color: #b8b8b8;
}

.ant-table-thead > tr > th {
    background-color: #f8f9fa;
    color: black;
    font-weight: bold;
}

.ant-table-tbody > tr > td {
    padding: 8px;
}

.ant-table-cell {
    vertical-align: middle;
}
