.ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.ant-table {
  min-width: 100%;
}

.ant-table-container {
  overflow-x: auto;
}

.ant-table-body {
  overflow-x: auto;
}

.table-search {
  display: flex;
  justify-content: space-between;
}
