.content-page-header {
    background-color: #ffffff;
    border-bottom: 1px solid #eaeaea;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
    margin-bottom: 32px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    padding-left: 1%; /* 왼쪽 패딩 제거 */
}

.content-page-header:hover {
    background-color: #f9f9f9;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.12);
}

.content-page-header .ant-typography {
    color: #333333;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.breadcrumb-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #bfbfbf;
    font-weight: 400;
}

.breadcrumb-container .ant-breadcrumb {
    color: inherit;
}

.breadcrumb-container .ant-breadcrumb-separator {
    color: #bfbfbf;
    margin: 0 8px;
}

.breadcrumb-container .ant-breadcrumb a {
    color: #595959;
    font-weight: 600;
    text-decoration: none;
}

.breadcrumb-container .ant-breadcrumb a:hover {
    color: #000000;
}
