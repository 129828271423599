.content-edit-detail-button {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.content-edit-detail-button button {
  background-color: yellow;
}

.content-edit-detail-button-generate {
  display: flex;
  margin-bottom: 20px;
  padding-right: 10px;
  gap: 10px;
}
