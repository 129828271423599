.table-header {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

.table-header .bulk-delete-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.table-header-left > * {
  margin-right: 10px; /* 모든 직계 자식 요소에 동일한 마진 적용 */
}

.table-header-left > *:last-child {
  margin-right: 0; /* 마지막 요소의 오른쪽 마진 제거 */
}

.table-header .table-header-left {
  display: flex;
  justify-content: left;
}

.table-header .table-header-right {
  display: flex;
  justify-content: right;
}

.table-header .table-header-right > * {
  margin-left: 10px;
}

.table-name {
  font-size: 24px;
  font-weight: bold;
}

.search-input-wrapper {
  width: 250px;
  margin-right: 8px;
}

/* Ant Design Input의 내부 스타일 오버라이드 */
.search-input-wrapper .ant-input {
  border-radius: 4px;
}

.search-input-wrapper .ant-input:focus {
  box-shadow: 0 0 0 2px yellow;
}
