.playlist-details-page {
    padding: 20px;
    background-color: #fff;
}

.playlist-details-form {
    max-width: 800px;
}

.playlist-sources, .playlist-stream-sources {
    margin-top: 20px;
}

.playlist-source, .playlist-stream {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
}

h1 {
    font-size: 24px;
    font-weight: bold;
}

h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.playlist-detail-update-button {
    background-color: white;
}

.playlist-detail-update-button button {
    background-color: yellow;
}
