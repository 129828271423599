.upload-video-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ant-progress {
    margin-left: 16px;
}

.ant-list-item {
    align-items: center;
}

/* styles.css */
.pretty-input {
    width: calc(50% - 8px);
    margin-right: 8px;
    border: 1px solid #d9d9d9;
    transition: all 0.3s ease-in-out;
  }
  
.pretty-input:last-of-type {
    margin-right: 0;
}
  
.pretty-input:hover,
.pretty-input:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 8px rgba(64, 169, 255, 0.5);
}
  
.pretty-textarea {
    width: calc(50% - 8px);
}

.pretty-textarea:hover,
.pretty-textarea:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 8px rgba(64, 169, 255, 0.5);
}