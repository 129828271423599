.content-details-page {
  padding: 20px;
  background-color: #fff;
}

.content-details-form {
  max-width: 800px;
}

.content-sources, .content-stream-sources {
  margin-top: 20px;
}

.content-source, .content-stream {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

input:read-only {
  background-color: #e9e9e9;
}

.content-details-edit {
  width: 70%;
}

.content-details-show {
  width: 30%;
}
