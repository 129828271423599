.ant-table-tbody > tr:hover > td {
  background-color: #c0f7f1 !important; /* 원하는 호버 색상으로 변경 */
}

.merged-cell {
  background-color: #ffffff;
  border-top: 2px solid #d9d9d9;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

.merged-cell.content-provider-cell {
  border-left: 2px solid #d9d9d9;
}

.merged-cell.title-cell {
  border-bottom: 5px solid #d9d9d9 !important;
}

.selected-row {
  background-color: #c2b8f9;
}

.selected-row .merged-cell {
  background-color: #bae7ff;
  border-color: #1890ff;
}

.column-title-toggle {
  cursor: pointer;
}

.toggle-icon {
  margin-left: 4px;
}

.title-cell.merged-cell {
  border-bottom: 2px solid #000;
}
