.custom-card {
  width: 1200px;
  border-radius: 20px;
  border-width: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-card .card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.custom-card .buttons-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.upload-description {
  padding-top: 10px;
  display: inline-block;
  font-weight: 900;
  font-size: 15px; /* 원하는 크기로 조절 가능 */
  font-weight: 500; /* 필요한 경우 글씨 굵기도 조절 가능 */
}
