.transcodeContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.transcodeItem {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  transition: all 0.3s ease;
}

.transcodeItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.transcodeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.transcodePreset {
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.transcodeStatus {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.completed {
  background-color: #28a745;
}

.in_progress {
  background-color: #ffc107;
}

.failed {
  background-color: #dc3545;
}

.transcodeUrl {
  margin-top: 12px;
}

.urlLabel {
  display: block;
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 4px;
}

.urlValue {
  display: block;
  font-size: 14px;
  color: #007bff;
  word-break: break-all;
}

.previewLabel {
  display: block;
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 4px;
  margin-top: 8px;
}
